const navLinks = [
  {
    sNo: "01. ",
    name: "About",
    link: "/",
  },
  {
    sNo: "02. ",
    name: "Skills",
    link: "#skills",
  },
  {
    sNo: "03. ",
    name: "Experience",
    link: "https://linkedin.com/in/harshitkumar2503",
  },
  {
    sNo: "04. ",
    name: "Work",
    link: "https://github.com/HarshitChauhan",
  },
  {
    sNo: "05. ",
    name: "Contact",
    link: "#contact",
  },
];

export default navLinks;