import * as React from "react"
import { Helmet } from "react-helmet";
import Loader from "../components/Loader";
import VersionOne from "../components/versionOne";


const IndexPage = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [])
  

  return (
    <React.Fragment >
      <Helmet htmlAttributes={{ lang: 'en-GB' }}>
      <meta name="description" content="The online website portfolio of Harshit Kumar, a software engineer." />
      </Helmet>
      { loading ?
        <Loader />
        :
        <VersionOne />
      }
    </React.Fragment>
  )
}

export default IndexPage

export const Head = () => <title>Harshit Kumar</title>
