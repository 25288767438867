import React, { useState } from 'react'
import { Link } from 'gatsby';
import navLinks from "../content/navLinks";
import { faBarsStaggered, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Resume from "../content/resume/HarshitKumar_Resume.pdf";

export default function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const downloadResume = () => {
    // using Java Script method to get PDF file
    fetch(Resume).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "HarshitKumar_Resume.pdf";
        alink.click();
      });
    });
  };
  
    return (
      <nav className="flex h-24 w-full flex-row items-center justify-between bg-primary px-4 ">
        {/* navbar signature */}
        <h1 className="ml-2 font-serif text-2xl text-quadary md:ml-4 md:text-4xl lg:text-5xl z-20">
          harshit <span className='text-red-500 text-3xl md:text-4xl relative -left-1 md:-left-2'>.</span>
        </h1>
        {/* navlinks */}
        <div className=" mx-2 my-6 flex flex-row items-center justify-around text-sm font-thin md:mx-4 lg:mx-10">
          <ul className="mx-2 hidden px-2 md:flex">
            {navLinks.map(({ name, link, sNo }) => (
              <li key={sNo} className="cursor-pointer md:px-2 lg:px-4 ">
                <Link to={link} target={"_blank"}>
                  <span className="text-quadary">{sNo}</span>
                  <span className="text-gray-200 duration-300 hover:text-quadary">
                    {name}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
          <button className="invisible grid h-10 w-20 cursor-pointer place-content-center  rounded-md border-[1px] border-quadary font-normal text-quadary duration-300  hover:bg-sky-900 hover:text-white md:visible md:px-4">
            <Link
              // to="https://drive.google.com/file/d/1pF5D3CVetuWEOqgzKnKg5OQUY-XrFWmM/view?usp=share_link"
              to={""}
              onClick={downloadResume}
              rel = "noreferrer"
              target={"_blank"}
              className="cursor-pointer"
            >
              Resume
            </Link>
          </button>
          <span class="flex h-3 w-3"></span>
        </div>

        {/* mobile menu */}
        <button
          className="md:hidden z-20 mr-4 p-4"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          {!isMenuOpen ? (
            <FontAwesomeIcon icon={faBarsStaggered} size="1x" inverse />
          ) : (
            <FontAwesomeIcon icon={faXmark} size="1x" inverse />
          )}
        </button>

        {isMenuOpen && (
          <div
            className={`md:hidden fixed top-0 z-10 flex h-full w-full flex-col items-center justify-center gap-10 bg-primary backdrop-blur-3xl backdrop-filter`}
          >
            <ul className="relative right-12 mx-2 flex flex-col gap-10 px-2 ">
              {navLinks.map(({ name, link, sNo }) => (
                <li key={sNo} className="cursor-pointer px-4 ">
                  <Link to={link} target={"_blank"} onClick={() => setMenuOpen(!isMenuOpen)}>
                    <span className="text-quadary">{sNo}</span>
                    <span className="text-gray-200 duration-300 hover:text-quadary">
                      {name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <button className="grid h-10 w-auto cursor-pointer  place-content-center rounded-md border-[1px] border-quadary px-4 font-normal  text-quadary duration-300 hover:bg-sky-900 hover:text-white">
              <Link
                // to="https://drive.google.com/file/d/1pF5D3CVetuWEOqgzKnKg5OQUY-XrFWmM/view?usp=share_link"
                to={""}
                onClick={downloadResume}
                rel = "noreferrer"
              target = '_blank'
                className="cursor-pointer"
              >
                Hey! here's my Resume
              </Link>
            </button>
            <span class="flex h-3 w-3"></span>
          </div>
        )}
      </nav>
  )
}
