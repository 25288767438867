import React from 'react';
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faHackerrank,
    faInstagram,
    faLinkedin,
    faTwitter,
  } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <section id="contact" className="flex h-screen w-full flex-col overflow-hidden  bg-primary px-6 py-4 font-sans md:px-40">
    <div className="mx:auto relative top-10 flex h-full w-full flex-col items-center justify-around text-slate-400 ">
      <span className="m-1 ml-0 p-2 font-heading text-base text-quadary">
        <span className="font-normal">What's Next?</span>
        <hr />
      </span>
      <span className="text-4xl font-semibold text-slate-300 ">
        Get In Touch
      </span>
      <span className="p-2 px-4 -translate-y-8 text-center text-base font-normal md:px-3 lg:px-20 ">
        Although I’m not currently looking for any new opportunities, my
        inbox is always open. Whether you have a question or just want to
        say hi, I’ll try my best to get back to you!
      </span>
      <button className="h-14 w-1/3 -translate-y-12 rounded-md border-2 border-green-500 text-lg font-medium text-green-600 duration-300 hover:bg-green-500 hover:text-white">
        <Link
          to="mailto:harshitkumar2503@gmail.com"
          className="cursor-pointer"
        >
          Say Hello
        </Link>
      </button>
      <div className="mb-10 flex flex-col items-center justify-center">
        <div className="m-2 flex w-full flex-row gap-8 items-center justify-center p-2">
          <Link
            to="https://github.com/HarshitChauhan/"
            className="flex cursor-pointer items-center justify-center p-1"
          >
            <FontAwesomeIcon icon={faGithub} size="1x" inverse/>
          </Link>
          <Link
            to="http://linkedin.com/in.harshitkumar2503/"
            className="flex cursor-pointer items-center justify-center p-1"
          >
            <FontAwesomeIcon icon={faLinkedin} size="1x" inverse/>
          </Link>
          <Link
            to="https://hackerrank.com/harshitkumar2503/"
            className="flex cursor-pointer items-center justify-center p-1"
          >
            <FontAwesomeIcon icon={faHackerrank} size="1x" inverse/>
          </Link>
          <Link
            to="https://twitter.com/HarshitKChauhan/"
            className="flex cursor-pointer items-center justify-center p-1"
          >
            <FontAwesomeIcon icon={faTwitter} size="1x" inverse/>
          </Link>
          <Link
            to="https://instagram.com/harshitkumarchauhan/"
            className="flex cursor-pointer items-center justify-center p-1"
          >
            <FontAwesomeIcon icon={faInstagram} size="1x" inverse/>
          </Link>
        </div>
        <span className="text-thin w-full text-center text-gray-500">
          Designed & Built by Harshit Kumar
        </span>
      </div>
    </div>
  </section>
  )
}
