import logo from "./logos/logo";

const frontendSkills = [
    {
        title: "React.JS",
        level: "Intermediate",
        img: `${logo.reactjs}`
    },
    {
        title: "JavaScript",
        level: "Intermediate",
        img: `${logo.javascript}`
    },
    {
        title: "HTML",
        level: "Advance",
        img: `${logo.html}`
    },
    {
        title: "Gatsby.JS",
        level: "Basic",
        img: `${logo.gatsbyjs}`
    },
    {
        title: "CSS",
        level: "Advance",
        img: `${logo.css}`
    },
    {
        title: "Tailwind CSS",
        level: "Advance",
        img: `${logo.tailwindcss}`
    },
]
const backendSkills = [
    {
        title: "Node.JS",
        level: "Intermediate",
        img: `${logo.nodejs}`
    },
    {
        title: "Java",
        level: "Intermediate",
        img: `${logo.java}`
    },
    {
        title: "Mulesoft",
        level: "Intermediate",
        img: `${logo.mulesoft}`
    },
    {
        title: "MySQL",
        level: "Advance",
        img: `${logo.mysql}`
    },
    {
        title: "MongoDB",
        level: "Intermediate",
        img: `${logo.mongodb}`
    },
    {
        title: "Redis",
        level: "Basic",
        img: `${logo.redis}`
    },
]
const otherSkills = [
    {
        title: "Figma",
        level: "Intermediate",
        img: `${logo.figma}`
    },
    {
        title: "Azure",
        level: "Basic",
        img: `${logo.azure}`
    },
    {
        title: "Redux",
        level: "Intermediate",
        img: `${logo.redux}`
    },
    {
        title: "Git",
        level: "Advance",
        img: `${logo.git}`
    },
    {
        title: "Firebase",
        level: "Intermediate",
        img: `${logo.firebase}`
    },
]

export { frontendSkills, backendSkills, otherSkills };