import React from "react";
import { Link } from "gatsby";

import Footer from "./Footer";
import Navbar from "./Navbar";
// import Skills from "./Skills";
import SkillsV2 from "./SkillsV2";

function VersionOne() {

  return (
    <React.Fragment>
      {/* navbar */}
      <Navbar/>

      {/* Body */}
        <section id="hero" className="top-20 flex h-screen w-full flex-col overflow-hidden bg-primary px-6 py-4 font-sans md:px-14 lg:px-40">
          <span className="m-1 ml-0 p-2 text-base text-quadary md:text-lg">
            Namaste, my name is
          </span>
          <h1 className="m-1 -mx-px pl-2 text-5xl font-bold text-slate-200 md:text-6xl lg:text-7xl">
            Harshit Kumar.
          </h1>
          <h1 className="m-1 ml-0 p-2 text-4xl text-slate-400 md:text-6xl lg:text-7xl">
            I build things for the web.
          </h1>
          <p className="text- m-2 mt-6 ml-0 p-2 text-lg text-slate-400 md:pr-40 md:text-lg lg:pr-64 lg:text-xl">
            I’m a software engineer specializing in building (and occasionally
            designing) exceptional digital experiences. Currently, I’m focused
            on building accessible, human-centered system integrations at
            <Link
              to="https://apisero.com/"
              className="text-quadary duration-200 hover:text-tertiary"
            >
              {" "}
              Apisero.
            </Link>
          </p>
        </section>

        {/* skills */}
              <SkillsV2 />
      {/* footer  */}
      <Footer />
    </React.Fragment>
  );
}

export default VersionOne;
