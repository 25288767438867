const logo = {
    azure: require('./azure.svg').default,
    css: require('./css.svg').default,
    expressjs: require('./expressjs.svg').default,
    figma: require('./figma.svg').default,
    firebase: require('./firebase.svg').default,
    gatsbyjs: require('./gatsbyjs.svg').default,
    git: require('./git.svg').default,
    html: require('./html.svg').default,
    java: require('./java.svg').default,
    javascript: require('./javascript.svg').default,
    mongodb: require('./mongodb.svg').default,
    mulesoft: require('./mulesoft.svg').default,
    mysql: require('./mysql.svg').default,
    nodejs: require('./nodejs.svg').default,
    reactjs: require('./reactjs.svg').default,
    redis: require('./redis.svg').default,
    redux: require('./redux.svg').default,
    tailwindcss: require('./tailwindcss.svg').default,
};

export default logo;

