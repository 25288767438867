import React from 'react';
import { BarLoader } from 'react-spinners';

function Loader() {
  return (
    <div className='flex flex-col justify-center w-screen h-screen items-center text-white bg-primary'>
        <div className='p-2'>
          <h1 className="ml-2 font-serif text-2xl text-white md:ml-4 md:text-4xl lg:text-5xl z-20">
          harshit <span className='text-rose-600 text-3xl md:text-4xl relative -left-1 md:-left-2'>.</span>
        </h1>
        </div>
        <BarLoader color="#64ffda" height={2} />
    </div>
  )
}

export default Loader;