import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  faCode,
  faChevronDown,
  faServer,
  faSwatchbook,
} from "@fortawesome/free-solid-svg-icons";
import {
  frontendSkills,
  backendSkills,
  otherSkills,
} from "../content/skills/skills";

export default function Skills() {
  // const [tabOpen, setTabOpen] = useState(false);
  const [tabFD, setTabFD] = useState(false);
  const [tabBD, setTabBD] = useState(false);
  const [tabOD, setTabOD] = useState(false);

  return (
    <section
      id="skills"
      className="flex h-full w-full flex-col overflow-hidden bg-primary px-6 py-4 font-sans text-slate-400 md:px-14 lg:px-40"
    >
      <div className="mx:auto relative flex h-full w-full flex-col items-center gap-8 py-4 text-slate-400">
        <span className="font-heading m-1 ml-0 p-2 text-base text-quadary">
          <span className={`font-normal`}>My Technical Skills</span>
          <hr />
        </span>
        <div className="grid grid-flow-row gap-y-2 md:flex md:flex-row md:gap-x-8">
          {/* skill set - 1  */}
          <div className="h-full p-2">
            {/* header */}
            <div className="rounded-md bg-gray-800 p-1">
              <div
                className="flex items-center justify-around gap-4 rounded-md bg-gray-800 p-2"
                onClick={() => {
                  setTabFD(!tabFD);
                  // setTabBD(false);
                  // setTabOD(false);
                }}
              >
                <FontAwesomeIcon icon={faCode} size="1x" inverse className="" />
                <div className="flex cursor-pointer flex-col items-start justify-center p-2">
                  <h1 className="title text-xl text-gray-200">
                    Frontend Development
                  </h1>
                  <span className="subtitle text-xs">More than 1 years</span>
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="1x"
                  inverse
                  className={`ml-auto transition-transform duration-300 ${
                    tabFD ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {/* content */}
              <div
                className={`flex flex-col gap-y-5 overflow-hidden transition-all duration-300 ${
                  tabFD ? "opacity-1 max-h-96" : "max-h-0 opacity-0"
                }`}
              >
                <div className="m-2 grid grid-cols-3 gap-4 pb-1">
                  {frontendSkills.map((skill, i) => (
                    <div
                      className="grid aspect-[3/4] place-items-center rounded"
                      key={i}
                    >
                      <div className="grid h-24 w-20 place-items-center rounded bg-gray-700 p-3 clip-hexa">
                        <img
                          src={skill.img}
                          alt={skill.title}
                          className="m-1 p-2 hover:animate-bounceSkill"
                        />
                      </div>
                      <h3 className="text-sm font-medium text-gray-200">
                        {skill.title}
                      </h3>
                      <span className="text-xs font-extralight text-gray-300">
                        {skill.level}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* backendSkills */}
          <div className="h-full p-2">
            {/* header */}
            <div className="rounded-md bg-gray-800 p-1">
              <div
                className="flex items-center justify-around gap-4 rounded-md bg-gray-800 p-2"
                onClick={() => {
                  setTabBD(!tabBD);
                  // setTabFD(false);
                  // setTabOD(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faServer}
                  size="1x"
                  inverse
                  className=""
                />
                <div className="flex cursor-pointer flex-col items-start justify-center p-2">
                  <h1 className="title text-xl text-gray-200">
                    Backend Development
                  </h1>
                  <span className="subtitle text-xs">More than 1.5 years</span>
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="1x"
                  inverse
                  className={`ml-auto transition-transform duration-300 ${
                    tabBD ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {/* content */}
              <div
                className={`flex flex-col gap-y-5 overflow-hidden transition-all duration-300 ${
                  tabBD ? "opacity-1 max-h-96" : "max-h-0 opacity-0"
                }`}
              >
                <div className="m-2 grid grid-cols-3 gap-4 pb-1">
                  {backendSkills.map((skill, i) => (
                    <div
                      className="grid aspect-[3/4] place-items-center rounded"
                      key={i}
                    >
                      <div className="grid h-24 w-20 place-items-center rounded bg-gray-700 p-3 clip-hexa">
                        <img
                          src={skill.img}
                          alt={skill.title}
                          className="m-1 p-2 hover:animate-bounceSkill"
                        />
                      </div>
                      <h3 className="text-sm font-medium text-gray-200">
                        {skill.title}
                      </h3>
                      <span className="text-xs font-extralight text-gray-300">
                        {skill.level}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* otherSkills */}
          <div className="h-full p-2">
            {/* header */}
            <div className="rounded-md bg-gray-800 p-1">
              <div
                className="flex items-center justify-around gap-4 rounded-md bg-gray-800 p-2"
                onClick={() => {
                  setTabOD(!tabOD);
                  // setTabFD(false);
                  // setTabBD(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faSwatchbook}
                  size="1x"
                  inverse
                  className=""
                />
                <div className="flex cursor-pointer flex-col items-start justify-center p-2">
                  <h1 className="title text-xl text-gray-200">Other Skills</h1>
                  <span className="subtitle text-xs">
                    Progressively Learning
                  </span>
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="1x"
                  inverse
                  className={`ml-auto transition-transform duration-300 ${
                    tabOD ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {/* content */}
              <div
                className={`flex flex-col gap-y-5 overflow-hidden transition-all duration-300 ${
                  tabOD ? "opacity-1 max-h-96" : "max-h-0 opacity-0"
                }`}
              >
                <div className="m-2 grid grid-cols-3 gap-2 pb-1">
                  {otherSkills.map((skill, i) => (
                    <div
                      className="grid aspect-[3/4] place-items-center rounded pb-2"
                      key={i}
                    >
                      <div className="grid h-24 w-20 place-items-center rounded bg-gray-700 p-3 clip-hexa">
                        <img
                          src={skill.img}
                          alt={skill.title}
                          className="m-1 p-2 hover:animate-bounceSkill"
                        />
                      </div>
                      <h3 className="text-sm font-medium text-gray-200">
                        {skill.title}
                      </h3>
                      <span className="text-xs font-extralight text-gray-300">
                        {skill.level}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
